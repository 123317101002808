<template>
  <div id="container">
    <section id="faq">
      <div class="wrap">
        <h2 class="main_ttl"><span>よくあるご質問</span></h2>
        <div class="cts_box">
          <p class="lead_txt">
            複眼経済塾のご利用方法や、よくあるご質問とその回答をまとめています。<br>
            その他のお困りのことがありましたら以下の「お問い合わせ」よりご質問ください。
          </p>
          <div class="button_wrap">
            <router-link to="/contacts/" class="submit basic arrow">お問い合わせ</router-link>
          </div>
        </div>

        <div class="cts_box">
          <div class="top_cts acc_menu mokuji">
            <div class="acc_tab open">
              <h3 class="sub_ttl"><span>目次</span></h3>
            </div>
            <div class="acc_cts">
              <div class="inner" v-for="category in fetch_data" :key="category.id">
                <h4 class="min_ttl">{{ category.title }}</h4>
                <ul class="dotted">
                  <li v-for="faq in category.faqs" :key="faq.id">
                    <a v-bind:href="`#anser-${faq.id}`" v-on:click="ankerClicked(`#anser-${faq.id}`)" class="link_txt no_margin">{{ faq.title }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="cts_box" v-for="category in fetch_data" :key="category.id">
          <h3 class="sub_ttl"><span>{{ category.title }}</span></h3>
          <template v-for="faq in category.faqs">
            <div :key="faq.id">
              <div class="anchor_point" v-bind:id="'anser-' + faq.id">
                <div class="acc_menu">
                  <div class="acc_tab">
                    <h3 class="min_ttl"><span>{{ faq.title }}</span></h3>
                  </div>
                  <div class="acc_cts" v-html="faq.body"/>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="button_wrap">
          <p class="lead_txt alnC">
            その他のお困りのことがありましたら<br class="sp">以下の「お問い合わせ」よりご質問ください。
          </p>
          <router-link to="/contacts/" class="submit basic arrow">お問い合わせ</router-link>
        </div>
      </div>
    </section>
    <div id="b_listBox">
      <ul id="b_list">
        <li>
          <router-link to="/">トップページ</router-link>
        </li>
        <li>よくあるご質問</li>
      </ul>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  data() {
    return {
      fetch_data: null
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}faqs.json`, {
            params: {
              draft: this.$route.query.draft
            },
          })
          .then(response => {
            this.fetch_data = response.data
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
            this.fetchDone()
            if (location.hash) {
              const hash = location.hash
              location.hash = ''
              setTimeout(() => {
                location.hash = hash
                //this.scrollTo(location.hash)
                this.ankerClicked(location.hash)
              }, 1000)
            }
          })
    },
    fetchDone() {
      // user js への onload処理
      if (typeof _million_onload !== 'undefined') {
        window._million_onload(this.fetch_data.uri);
      }
      /*
      window.$('.acc_menu .acc_cts').hide()
      window.$('.top_cts.acc_menu .acc_cts').show()
      window.$('.acc_menu .acc_tab').on('click', function () {
        window.$(this).next('.acc_cts').slideToggle()
        window.$(this).toggleClass('open')
      });
      */
    },
    ankerClicked(hash) {
      const element = window.$(`${hash} .acc_tab`)
      if (!window.$(element).hasClass('open')) {
        setTimeout(() => {
          window.$(element).next('.acc_cts').slideToggle()
          window.$(element).toggleClass('open')
        }, 600)
      }
    },
  }
}
</script>
